<template>
    <div class="get-invoice">
        <h1>How to get an invoice for payment?</h1>
        <ol>
            <li>Through a support request</li>
            <li>Via an available communication channel from a web service specialist</li>
        </ol>
    </div>
</template>
<script>
export default {
    name: "howToGetInvoice",
    data() {
        return {
            locale: process.env.VUE_APP_LOCALE,
        }
    }
}
</script>
<style lang="scss" scoped>
.get-invoice {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    h1 {
        line-height: normal;
    }

    ol,
    ul {
        list-style-position: inside;
    }
}
</style>